import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/landing-webdesign.js"
import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"

import Grid from "@material-ui/core/Grid"
import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/ParallaxAbout.jsx"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

import Intro from "../pages-sections/About-Us/Intro.jsx"

import Contact from "../pages-sections/About-Us/Contact.jsx"

const useStyles = makeStyles(styles)

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="About Us | Atlas Agency Toronto"
        description="SEO Company Toronto, we help you rank higher on Google searches. Our SEO company is proven to help multiple business of all sizes rank higher on Google."
        canonical="https://atlasagency.ca/about-us-seo-company-toronto/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand="Atlas Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax filter responsive>
        <div className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <h1 className={classes.title}>About Us</h1>
              <br />
            </Grid>
          </Grid>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Intro />
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
