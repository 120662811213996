import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons

// core components

import Grid from "@material-ui/core/Grid"

import styles from "../../jss/intro.js"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center" style={{ marginTop: "50px" }}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h2 className={classes.title}>Who We Are</h2>
          <h5 className={classes.description}>
            Experienced web developers and SEO experts. We help you build modern
            and mobile optimized websites. We help companies of all sizes
            significantly increase web traffic by ranking higher on Google
            searches. We will identify which keywords are best for your business
            and optimize your web pages accordingly.
          </h5>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h2 className={classes.title}>Vision</h2>
          <h5 className={classes.description}>
            Modernize website designs for businesses that are looking to grow
            their online presence. Empower businesses with modern technologies
            developed by our team of developers.
          </h5>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h2 className={classes.title}>Mission</h2>
          <h5 className={classes.description}>
            We help businesses of all sizes gain online presence using the
            latest web development technologies and coding platforms. We help
            businesses rank higher on Google searches by optimizing websites as
            per the latest Google SEO guidelines.
          </h5>
        </Grid>
      </Grid>
    </div>
  )
}
